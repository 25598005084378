import { Tasks } from '@air/api';
import { useIsLoggedIn } from '@air/utils-auth';
import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { usePreviousDistinct, useSessionStorage } from 'react-use';

import { useAccountIsFederated } from '~/swr-hooks/account/useAccountIsFederated';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { getWorkspacesListKey } from '~/swr-hooks/workspaces/useWorkspaces';

import { AccountProvisioningFailedState } from '../components/AccountProvisioningFailedState';
import { AccountProvisionInProgress } from '../components/AccountProvisionInProgress';
import { useAccountProvisioner } from '../hooks/useAccountProvisioner';

type AccountProvisionerProviderProps = {
  children: React.ReactNode;
};

type AccountProvisioner = Awaited<ReturnType<typeof Tasks.listTasks<undefined, 'AccountProvisioner'>>>['items'][number];

type AccountProvisionProviderContextValue = {
  accountProvisioner: AccountProvisioner | null;
  continuedWithAccountProvisioningError: boolean;
  setContinuedWithAccountProvisioningError: (value: boolean) => void;
  hasError: boolean;
  isFetchPending: boolean;
};

const defaultValue: AccountProvisionProviderContextValue = {
  accountProvisioner: null,
  continuedWithAccountProvisioningError: false,
  setContinuedWithAccountProvisioningError: () => {
    /**/
  },
  hasError: false,
  isFetchPending: false,
};

const AccountProvisionerProviderContext = createContext(defaultValue);

export const AccountProvisionerProvider = ({ children }: AccountProvisionerProviderProps) => {
  const { isLoggedIn, isLoggedInFetched } = useIsLoggedIn();
  const { data: accountProvisioner = null, isFetched: isAccountProvisionerFetched, isError } = useAccountProvisioner();
  const { accountIsFederated } = useAccountIsFederated();
  const prevStatus = usePreviousDistinct(accountProvisioner?.status);
  const queryClient = useQueryClient();
  const hasError = isError || (!!accountProvisioner && ['failed', 'exception'].includes(accountProvisioner.status));
  const inProgress = accountProvisioner && ['pending', 'active'].includes(accountProvisioner.status);
  const isFetchPending =
    !(isLoggedInFetched && !isLoggedIn) && !(isLoggedInFetched && isLoggedIn && isAccountProvisionerFetched);

  const [continuedWithAccountProvisioningError, setContinuedWithAccountProvisioningError] = useSessionStorage<boolean>(
    'continuedWithAccountProvisioningError',
  );

  // Invalidate workspaces list query when accountProvisioner status changes to 'succeeded'
  useEffect(() => {
    const currentStatus = accountProvisioner?.status;
    if (prevStatus && currentStatus === 'succeeded' && prevStatus !== currentStatus) {
      queryClient.invalidateQueries({ queryKey: getWorkspacesListKey() });
    }
  }, [prevStatus, accountProvisioner?.status, queryClient]);

  const value = useMemo<AccountProvisionProviderContextValue>(
    () => ({
      accountProvisioner,
      continuedWithAccountProvisioningError,
      setContinuedWithAccountProvisioningError,
      hasError,
      isFetchPending,
    }),
    [
      accountProvisioner,
      continuedWithAccountProvisioningError,
      hasError,
      isFetchPending,
      setContinuedWithAccountProvisioningError,
    ],
  );

  const contents = useMemo(() => {
    if (inProgress) {
      return <AccountProvisionInProgress />;
    }

    if (hasError) {
      return continuedWithAccountProvisioningError ? children : <AccountProvisioningFailedState />;
    }

    if (!isLoggedInFetched || (isLoggedIn && accountIsFederated && !isAccountProvisionerFetched)) {
      return null;
    }

    // If we are here, it means that accountProvisioner is fetched and there is no error
    return children;
  }, [
    accountIsFederated,
    children,
    continuedWithAccountProvisioningError,
    hasError,
    inProgress,
    isAccountProvisionerFetched,
    isLoggedIn,
    isLoggedInFetched,
  ]);

  if (!contents) {
    return null;
  }

  return (
    <AccountProvisionerProviderContext.Provider value={value}>{contents}</AccountProvisionerProviderContext.Provider>
  );
};

export function useAccountProvisionerContext() {
  const context = useContext(AccountProvisionerProviderContext);

  // We are not throwing an error here because we want to allow the usage of this hook outside of the provider.
  // In that case, the hook will return the default value. This is useful for public components that are not
  // wrapped by the provider.
  if (isDevOrTestStage() && context === defaultValue) {
    console.info(
      'useAccountProvisionerContext is not used within an AccountProvisionerProviderContext, returning default value.',
    );
  }

  return context;
}
