import { Air } from '@air/next-icons';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { Routes } from '~/constants/routes';

const LogoLink = (props: LinkProps & { className?: string }) => (
  <Link {...props}>
    <VisuallyHidden>Go back to landing page.</VisuallyHidden>
    <Air className="w-[76px] fill-blue-9" aria-hidden="true" />
  </Link>
);

type AccountProvisionLayoutProps = {
  children: React.ReactNode;
};

export const AccountProvisionLayout = ({ children }: AccountProvisionLayoutProps) => {
  return (
    <section className="relative flex h-full w-full items-center bg-[url('/assets/illustrations/swirl-grey-light.svg')] bg-cover bg-no-repeat text-center dark:bg-[url('/assets/illustrations/swirl-grey-dark.svg')]">
      <div className="m-0 box-border w-full min-w-0 md:min-h-[100vh]">
        <header className="m-0 box-border flex h-[70px] w-full min-w-0 items-center justify-between border-t-2 border-solid border-[--colors-teal7]">
          <LogoLink className="ml-6 md:ml-8" href={Routes.root} />
        </header>
        <div className="flex h-[calc(100vh-240px)] flex-col items-center justify-center p-5">{children}</div>
      </div>
    </section>
  );
};
