import { isFederatedAuthProvider } from '@air/api/shared-utils';

import { useAccountAuthProvider } from './useAccountAuthProvider';

export const useAccountIsFederated = () => {
  const { accountAuthProvider } = useAccountAuthProvider();
  const accountIsFederated = accountAuthProvider ? isFederatedAuthProvider(accountAuthProvider) : false;

  return {
    accountIsFederated,
  };
};
