import React from 'react';

import { AccountProvisionLayout } from './AccountProvisionLayout';
import { SamlSSOInProgress } from './SamlSSOInProgress';

export const AccountProvisionInProgress = () => {
  return (
    <AccountProvisionLayout>
      <SamlSSOInProgress />
    </AccountProvisionLayout>
  );
};
