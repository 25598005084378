import { Tasks } from '@air/api';
import { useIsLoggedIn } from '@air/utils-auth';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useAccountIsFederated } from '~/swr-hooks/account/useAccountIsFederated';

const ACCOUNT_PROVISIONER = 'ACCOUNT_PROVISIONER';

export const getAccountProvisionerKey = () => {
  return [ACCOUNT_PROVISIONER];
};

export const useAccountProvisioner = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { accountIsFederated } = useAccountIsFederated();
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);

  return useQuery({
    queryKey: getAccountProvisionerKey(),
    queryFn: async () => {
      const tasks = await Tasks.listTasks({
        options: {
          limit: 1,
          type: 'AccountProvisioner',
        },
      });
      const result = tasks.items[0] ?? null;
      setRefetchInterval(result && ['pending', 'active'].includes(result.status) ? 2000 : false);
      return result;
    },
    refetchInterval,
    staleTime: Infinity,
    enabled: isLoggedIn && accountIsFederated,
  });
};
